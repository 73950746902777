import Form from '../../../../public/core/inc/form';
import axios from 'axios';

/* register form calculate */
let contactForm = new Form();
contactForm.wrapper = '#contactForm';
contactForm.url = API.CONTACT;
contactForm.method = 'POST';

contactForm.prepareData = () => {
    var myForm = document.getElementById('contactForm');
    contactForm.data = new FormData(myForm);
}

contactForm.afterDone = (data) => {
    if (!data.error) {
        window.location.href = data.data.url
    }
}

contactForm.handleSubmit();

/*--- Change Responsive Moble Of Page Services ---*/

$(document).ready(function() {
    var prev = '<div class="prev-chervon"><img src="' + (window.THEME_ASSET || 'assets') + '/images/icons/chevron-l.svg"/></div>',
        next = '<div class="next-chervon"><img src="' + (window.THEME_ASSET || 'assets') + '/images/icons/chevron-r.svg"/></div>';
    $(".introduce_service, .benefit_course, .intro_trainer").slick({
        slidesToShow: 1,
        slidesToScroll: 1,
        mobileFirst: true,
        prevArrow: prev,
        nextArrow: next,
        responsive: [{
            breakpoint: 560,
            settings: 'unslick'
        }]
    });

    $(window).on('resize', function() {
        $('.introduce_service, .benefit_course, .intro_trainer').slick('resize');
    });
});
